import Vue from 'vue';
import App from './App.vue';
import router from './router';

import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies;
Vue.use(VueCookies)

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/utils/flexible.js';

// 引入全局css
import './assets/scss/style.scss';



//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;
// import 'echarts/map/js/china.js'

Vue.config.productionTip = false;


new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');