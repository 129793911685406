import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () =>
        import ('../views/index.vue')
}]
const router = new VueRouter({
        routes
    })
    // const originalPush = VueRouter.prototype.push
    // VueRouter.prototype.push = function push(location) {
    //     return originalPush.call(this, location).catch(err => err)
    // }
export default router